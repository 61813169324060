// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bZ_k4";
export var caseStudyBackground__lineColor = "bZ_kW";
export var caseStudyHead__imageAbsolute = "bZ_k9";
export var caseStudyHead__imageWrapper = "bZ_kT";
export var caseStudyProjectsSection = "bZ_k5";
export var caseStudyQuote__bgLight = "bZ_k2";
export var caseStudyQuote__bgRing = "bZ_k1";
export var caseStudySolution__ring = "bZ_kZ";
export var caseStudyTech = "bZ_k3";
export var caseStudy__bgDark = "bZ_kS";
export var caseStudy__bgLight = "bZ_kR";